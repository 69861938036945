import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";

// lazy load all the views
//user

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// dashboard
const Dashboard2 = React.lazy(() => import("../pages/dashboard/Dashboard2/"));

// apps
const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"));
// - ecommece pages
const EcommerceDashboard = React.lazy(
  () => import("../pages/apps/Ecommerce/Dashboard/")
);
const ProductDetails = React.lazy(
  () => import("../pages/apps/Ecommerce/ProductDetails")
);
const ProductEdit = React.lazy(
  () => import("../pages/apps/Ecommerce/ProductEdit")
);
const Orders = React.lazy(
  () => import("../pages/apps/Ecommerce/Orders/OrdersList")
);
const SalesManagement = React.lazy(
  () => import("../pages/apps/Ecommerce/Orders/SalesManagement")
);
const CreateOrders = React.lazy(
  () => import("../pages/apps/Ecommerce/Orders/CreateOrder")
);
const EditOrder = React.lazy(
  () => import("../pages/apps/Ecommerce/Orders/EditOrder")
);
const OrderDetails = React.lazy(
  () => import("../pages/apps/Ecommerce/Orders/OrderDetails")
);
const Cart = React.lazy(() => import("../pages/apps/Ecommerce/Cart"));
const Checkout = React.lazy(() => import("../pages/apps/Ecommerce/Checkout"));
const Categories = React.lazy(
  () => import("../pages/apps/Ecommerce/Categories/Categories")
);
const CreateCategory = React.lazy(
  () => import("../pages/apps/Ecommerce/Categories/CreateCategory")
);
const Notes = React.lazy(() => import("../pages/apps/Ecommerce/Notes/Notes"));
const CreateNotes = React.lazy(
  () => import("../pages/apps/Ecommerce/Notes/CreateNotes")
);
const ViewNotes = React.lazy(
  () => import("../pages/apps/Ecommerce/Notes/ViewNotes")
);
const Tasks = React.lazy(() => import("../pages/apps/Ecommerce/Tasks/Tasks"));
const CreateTasks = React.lazy(
  () => import("../pages/apps/Ecommerce/Tasks/CreateTasks")
);
const ViewTasks = React.lazy(
  () => import("../pages/apps/Ecommerce/Tasks/ViewTasks")
);

const AM = React.lazy(
  () => import("../pages/apps/Ecommerce/AccountManagement/AM")
);
const CreateAM = React.lazy(
  () => import("../pages/apps/Ecommerce/AccountManagement/CreateAM")
);
const ViewAM = React.lazy(
  () => import("../pages/apps/Ecommerce/AccountManagement/ViewAM")
);

const Notification = React.lazy(
  () => import("../pages/apps/Ecommerce/Notification/Notification")
);
const Crousel = React.lazy(
  () => import("../pages/apps/Ecommerce/Crousel/Crousel")
);
const InputFields = React.lazy(
  () => import("../pages/apps/Ecommerce/InputFields/InputFields")
);
const ProductList = React.lazy(
  () => import("../pages/apps/Ecommerce/Product/ProductList")
);
const CreateProduct = React.lazy(
  () => import("../pages/apps/Ecommerce/Product/CreateProduct")
);

const CreateCoupon = React.lazy(
  () => import("../pages/apps/Ecommerce/Coupon/CreateCoupon")
);
const CouponList = React.lazy(
  () => import("../pages/apps/Ecommerce/Coupon/CouponList")
);
const ReviewList = React.lazy(
  () => import("../pages/apps/Ecommerce/Reviews/ReviewList")
);
const EditReview = React.lazy(
  () => import("../pages/apps/Ecommerce/Reviews/EditReview")
);
const QuestionAndAnswerList = React.lazy(
  () =>
    import("../pages/apps/Ecommerce/QuestionAndAnswer/QuestionAndAnswerList")
);
const EditQuestionAndAnswer = React.lazy(
  () =>
    import("../pages/apps/Ecommerce/QuestionAndAnswer/EditQuestionAndAnswer")
);

const CreateBlog = React.lazy(
  () => import("../pages/apps/Ecommerce/Blog/CreateBlog")
);

const BlogList = React.lazy(
  () => import("../pages/apps/Ecommerce/Blog/BlogList")
);

const FamilySharingList = React.lazy(
  () => import("../pages/apps/Ecommerce/FamilySharing/FamilySharingList")
);
const FamilySharingSettings = React.lazy(
  () => import("../pages/apps/Ecommerce/FamilySharing/CreateSettings")
);
const CredentialsList = React.lazy(
  () => import("../pages/apps/Ecommerce/FamilySharing/CredentialsList")
);
const CreateCredentials = React.lazy(
  () => import("../pages/apps/Ecommerce/FamilySharing/CreateCredentials")
);
const EditCredentials = React.lazy(
  () => import("../pages/apps/Ecommerce/FamilySharing/EditCredentials")
);
// -tickets
const TicketsList = React.lazy(
  () => import("../pages/apps/Tickets/List/index")
);
const TicketsDetails = React.lazy(
  () => import("../pages/apps/Tickets/Details/index")
);

const CustomersList = React.lazy(
  () => import("../pages/apps/Customers/Customers")
);
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// root routes
// const rootRoute: RoutesProps = {
//   path: "/",
//   exact: true,
//   element: () => <Root />,
//   route: Route,
// };

// dashboards

const dashboardRoutes: RoutesProps = {
  path: "/aa",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  roles: ["Admin"],
  children: [
    {
      path: "/dashboard",
      name: "Dashboard 2",
      element: <Dashboard2 />,
      route: PrivateRoute,
    },
    {
      path: "/",
      name: "Home",
      element: <Navigate to={"/dashboard"} />,
    },
  ],
};

const ecommerceAppRoutes = {
  path: "/apps/ecommerce",
  name: "eCommerce",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "shopping-cart",
  children: [
    {
      path: "/apps/ecommerce/product-details",
      name: "Product Details",
      element: <ProductDetails />,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/edit-product",
      name: "Product Edit",
      element: <ProductEdit />,
      route: PrivateRoute,
    },

    {
      path: "/apps/productManagement/categories",
      name: "Product Categories",
      element: <Categories />,
      route: PrivateRoute,
    },
    {
      path: "/apps/productManagement/categories/create",
      name: "Create Product Categories",
      element: <CreateCategory />,
      route: PrivateRoute,
    },
    {
      path: "/apps/productManagement/categories/:editId/edit",
      name: "Edit Product Categories",
      element: <CreateCategory />,
      route: PrivateRoute,
    },
    {
      path: "/apps/notes",
      name: "Notes",
      element: <Notes />,
      route: PrivateRoute,
    },
    {
      path: "/apps/notes/create",
      name: "Create Notes",
      element: <CreateNotes />,
      route: PrivateRoute,
    },
    {
      path: "/apps/notes/:editId/edit",
      name: "Edit Notes",
      element: <CreateNotes />,
      route: PrivateRoute,
    },
    {
      path: "/apps/notes/:editId/view",
      name: "View Notes",
      element: <ViewNotes />,
      route: PrivateRoute,
    },
    {
      path: "/apps/notifications",
      name: "Notifications",
      element: <Notification />,
      route: PrivateRoute,
    },
    {
      path: "/apps/slider",
      name: "Slider",
      element: <Crousel />,
      route: PrivateRoute,
    },
    {
      path: "/apps/productManagement/inputfields",
      name: "Input Fields",
      element: <InputFields />,
      route: PrivateRoute,
    },
    {
      path: "/apps/productManagement/products",
      name: "Products",
      element: <ProductList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/productManagement/products/create",
      name: "Products-Create",
      element: <CreateProduct />,
      route: PrivateRoute,
    },
    {
      path: "/apps/productManagement/products/:editId/edit",
      name: "Products-Edit",
      element: <CreateProduct />,
      route: PrivateRoute,
    },
    {
      path: "/apps/productManagement/coupons",
      name: "Products",
      element: <CouponList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/productManagement/coupons/create",
      name: "Products-Create",
      element: <CreateCoupon />,
      route: PrivateRoute,
    },
    {
      path: "/apps/productManagement/coupons/:editId/edit",
      name: "Products-Edit",
      element: <CreateCoupon />,
      route: PrivateRoute,
    },

    {
      path: "/apps/customerInteraction/review",
      name: "Review-List",
      element: <ReviewList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/customerInteraction/review/:editId/edit",
      name: "Review-Edit",
      element: <EditReview />,
      route: PrivateRoute,
    },
    {
      path: "/apps/customerInteraction/qa",
      name: "qa-List",
      element: <QuestionAndAnswerList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/customerInteraction/qa/:editId/edit",
      name: "qa-Edit",
      element: <EditQuestionAndAnswer />,
      route: PrivateRoute,
    },
    {
      path: "/apps/customerInteraction/blog",
      name: "qa-List",
      element: <BlogList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/customerInteraction/blog/create",
      name: "blog-Crate",
      element: <CreateBlog />,
      route: PrivateRoute,
    },
    {
      path: "/apps/customerInteraction/blog/:editId/edit",
      name: "blog-Edit",
      element: <CreateBlog />,
      route: PrivateRoute,
    },
    {
      path: "/apps/familySharing",
      name: "family-sharing-list",
      element: <FamilySharingList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/familySharing/settings",
      name: "family-sharing-settings-create",
      element: <FamilySharingSettings />,
      route: PrivateRoute,
    },
    {
      path: "/apps/familySharing/settings/:editId/edit",
      name: "family-sharing-settings-edit",
      element: <FamilySharingSettings />,
      route: PrivateRoute,
    },
    {
      path: "/apps/familySharing/:familySharingId/credentials",
      name: "family-sharing-credentials",
      element: <CredentialsList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/familySharing/:familySharingId/credentials/create",
      name: "family-sharing-credentials-create",
      element: <CreateCredentials />,
      route: PrivateRoute,
    },
    {
      path: "/apps/familySharing/:familySharingId/credentials/:credentialsId/edit",
      name: "family-sharing-credentials-edit",
      element: <EditCredentials />,
      route: PrivateRoute,
    },
  ],
};
const taskRoutes = {
  path: "/apps/task",
  name: "Tasks",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "aperture",
  children: [
    {
      path: "/apps/tasks",
      name: "Tasks",
      element: <Tasks />,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/create",
      name: "Create Tasks",
      element: <CreateTasks />,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/:editId/edit",
      name: "Edit Tasks",
      element: <CreateTasks />,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/:editId/view",
      name: "View Tasks",
      element: <ViewTasks />,
      route: PrivateRoute,
    },
  ],
};
const accountManagementRoutes = {
  path: "/apps/amaa",
  name: "Account Management",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "aperture",
  children: [
    {
      path: "/apps/am",
      name: "Account Management",
      element: <AM />,
      route: PrivateRoute,
    },
    {
      path: "/apps/am/create",
      name: "Create Account Management",
      element: <CreateAM />,
      route: PrivateRoute,
    },
    {
      path: "/apps/am/:editId/edit",
      name: "Edit Account Management",
      element: <CreateAM />,
      route: PrivateRoute,
    },
    {
      path: "/apps/am/:editId/view",
      name: "View Account Management",
      element: <ViewAM />,
      route: PrivateRoute,
    },
  ],
};
const ticketsRoutes = {
  path: "/apps/tickets",
  name: "Tickets",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "aperture",
  children: [
    {
      path: "/apps/tickets/list",
      name: "List",
      element: <TicketsList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/tickets/list/:editId/edit",
      name: "Details",
      element: <TicketsDetails />,
      route: PrivateRoute,
    },
  ],
};
const orderManagementRoutes = {
  path: "/apps/customer",
  name: "Customers",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "aperture",
  children: [
    {
      path: "/apps/orderManagement/orders",
      name: "Orders",
      element: <Orders />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orderManagement/salesHistory",
      name: "Sales Management",
      element: <SalesManagement />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orderManagement/orders/create",
      name: "Create Orders",
      element: <CreateOrders />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orderManagement/orders/:editId/edit",
      name: "Edit Orders",
      element: <EditOrder />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orderManagement/orders/:editId/details",
      name: "Order Details",
      element: <OrderDetails />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orderManagement/customers",
      name: "Customers",
      element: <CustomersList />,
      route: PrivateRoute,
    },
  ],
};

const appRoutes = [
  orderManagementRoutes,
  ecommerceAppRoutes,
  ticketsRoutes,
  taskRoutes,
  accountManagementRoutes,
];

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes = [
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
];
// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[], parentRoles: string[] = []) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    // Inherit parent roles if child roles don't exist
    const mergedRoles = item.roles ? item.roles : parentRoles;

    // Create a new object with inherited roles
    const newItem: RoutesProps = {
      ...item,
      roles: mergedRoles,
    };

    flatRoutes.push(newItem);

    if (typeof newItem.children !== "undefined") {
      flatRoutes = [
        ...flatRoutes,
        ...flattenRoutes(newItem.children, mergedRoles),
      ];
    }
  });

  return flatRoutes;
};

// All routes
const authProtectedRoutes = [dashboardRoutes, ...appRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
// const userRoutes = flattenRoutes([...publicUserRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
  // userRoutes,
};
