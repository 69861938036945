import { couldStartTrivia } from "typescript";
import config from "../config";
import { EHttpMethod } from "../types/enums/HttpRequests.enums";
const AUTH_SESSION_KEY = "ubold_user";

class HttpService {
  private baseURL: string;

  constructor() {
    this.baseURL = config.API_URL as any;
  }

  // Get authorization token for requests
  private getAuthorization(): Record<string, string> {
    // const accessToken = Cookies.get("AccessToken") || "";
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    const accessToken = user ? JSON.parse(user).accessToken : "";
    return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
  }

  // Initialize service configuration
  public service(): HttpService {
    return this;
  }

  // Set up request headers
  private setupHeaders(hasAttachment?: boolean): Record<string, string> {
    return hasAttachment
      ? { credentials: "include", ...this.getAuthorization() }
      : { "Content-Type": "application/json", ...this.getAuthorization() };
  }

  // Handle HTTP requests using Fetch API
  private async request<T>(
    method: EHttpMethod,
    url: string,
    // eslint-disable-next-line no-undef
    options: RequestInit
  ): Promise<T> {
    try {
      const customOptions = {
        headers: new Headers(options.headers),
        ...options,
      };

      const response = await fetch(`${this.baseURL}${url}`, {
        method,
        ...customOptions,
        body: options.body, // Include the body property if it exists
      });
      return (await response.json()) as T;
    } catch (error) {
      return this.normalizeError(error);
    }
  }

  // Perform GET request
  public async get<T>(
    url: string,
    customOptions: Record<string, any> = {}
  ): Promise<T> {
    const hasAttachment = false;
    // eslint-disable-next-line no-undef
    const options: RequestInit = {
      headers: this.setupHeaders(hasAttachment),
      ...customOptions,
    };

    return this.request<T>(EHttpMethod.GET, url, options);
  }

  // Perform POST request
  public async push<T, P>(
    url: string,
    payload: any,
    hasAttachment?: boolean
  ): Promise<T> {
    return this.request<T>(EHttpMethod.POST, url, {
      body: hasAttachment ? payload : JSON.stringify(payload),
      headers: this.setupHeaders(hasAttachment),
    });
  }

  // Perform UPDATE request
  public async update<T, P>(
    url: string,
    payload: P,
    hasAttachment?: boolean
  ): Promise<T> {
    return this.request<T>(EHttpMethod.PUT, url, {
      body: hasAttachment ? (payload as any) : JSON.stringify(payload),
      headers: this.setupHeaders(hasAttachment),
    });
  }

  // Perform DELETE request
  public async remove<T>(url: string): Promise<T> {
    const hasAttachment = false;
    return this.request<T>(EHttpMethod.DELETE, `${url}`, {
      headers: this.setupHeaders(hasAttachment),
    });
  }

  // Normalize errors
  private normalizeError(error: any): Promise<any> {
    return Promise.reject(error);
  }
}

export { HttpService as default };
