import InputFields from "../pages/apps/Ecommerce/InputFields/InputFields";

export const API_URL = {
  LOGIN: {
    USER: "/auth/login",
    ADMIN: "/auth/admin/login",
  },
  CATEGORY: {
    BASE: "/category",
  },
  NOTES: {
    BASE: "/notes",
  },
  TASKS: {
    BASE: "/todos",
  },
  AM: {
    BASE: "/account",
  },
  NOTIFICATION: {
    BASE: "/notification",
  },
  INPUT_FIELDS: {
    BASE: "/dynamic-field",
  },
  CROUSEL: {
    BASE: "/crousel",
  },
  PRODUCTS: {
    BASE: "/product",
    ADMIN: "/product/admin/get",
    SEARCH: "/product/products/search",
  },
  COUPON: {
    BASE: "/coupon",
  },
  ORDERS: {
    BASE: "/order",
    NUMERIC: "/order/numeric",
    ADMIN: "/order/create/admin",
    SEARCH: "/order/order/search",
    NOTE: "/order/note",
  },
  USERS: {
    BASE: "/users",
    AUTH: "/auth/users",
    REGISTER: "/auth/register",
    SEARCH: "/auth/users/search",
    DELETE: "/auth/users",
  },
  REVIEW: {
    BASE: "/review/create",
    FIND_ALL: "/review/find/all",
    REPLY: "/review/reply/create",
    UPDATE_REPLY: "/review/reply/update",
    FIND_BY_ID: "/review/find-admin",
    DELETE: "/review/remove",
    DELETE_REPLY: "/review/remove/reply",
  },
  QUESTION_AND_ANSWER: {
    BASE: "/qa/create",
    FIND_ALL: "/qa/find/all",
    REPLY: "/qa/answer/create",
    UPDATE_ANSWER: "/qa/answer/update",
    FIND_BY_ID: "/qa/find-admin",
    DELETE: "/qa/remove",
    DELETE_REPLY: "/qa/remove/answer",
  },
  BLOG: {
    BASE: "/blog",
    ALL: "/blog/all",
  },
  FAMILY_SHARING: {
    BASE: "/family-sharing",
    CREDENTIALS: "/family-sharing/credentials",
    CREDENTIALS_ONE: "/family-sharing/credentials/one",
    CREATE_CREDENTIALS: "/family-sharing/create-credentials",
    UPDATE_CREDENTIALS: "/family-sharing/update-credentials",
  },
  TICKETS: {
    BASE: "/ticketing",
  },
};
